<template>
	<div class="card-outer" v-if="data.price !== undefined">
		<!-- outbound -->
		<div class="card-inner">
			<div class="flight-opt-row">
				<div class="flight-details">
					<div class="logo-container">
						<img class="airline-logo" alt="Airline Logo" width="36px" :src="getCarrierLogoPath(data.carrier.code)" @error="setDefaultCarrierLogo" />
						<div class="carrier-code" v-text="data.carrier.code"></div>
					</div>

					<div class="detail start">
						<div class="time">{{ formatTime(outboundFlightsData.departureTime) }}</div>
						<div class="airport">{{ outboundFlightsData.origin }}</div>
					</div>

					<div class="journey">
						<div class="journey-length">
							{{ formatTimeRange(outboundFlightsData.journeyLength) }}
						</div>
						<div class="line">
							<div class="small-line"></div>
							<div class="ball start"></div>
							<div class="layover-balls">
								<div v-for="layover in outboundFlightsData.changes" :key="layover" class="ball middle"></div>
							</div>
							<div class="ball end"></div>
						</div>
						<div class="layovers">
							<div v-if="outboundFlightsData.isDirect">
								Direct
							</div>
							<div v-else>
								{{outboundFlightsData.changes}} layovers
							</div>
						</div>
					</div>
					
					<div class="detail end">
						<div class="time">
							{{ formatTime(outboundFlightsData.arrivalTime) }}
						</div>
						<div class="airport">
							{{ outboundFlightsData.destination }}
						</div>
					</div>
				</div>
				<div class="airline-name">{{data.carrier.name}}</div>
			</div>
			<!-- return -->
			<div class="flight-opt-row" v-if="returnFlightsData.length != 0">
				<div class="flight-details">
					<div class="logo-container">
						<img class="airline-logo" alt="Airline Logo" width="36px" :src="getCarrierLogoPath(returnFlightsData[0].carrierCode)" @error="setDefaultCarrierLogo" />
						<div class="carrier-code" v-text="returnFlightsData[0].carrierCode"></div>
					</div>
					<div class="detail start">
						<div class="time">{{ formatTime(returnFlightsData[0].departureTime) }}</div>
						<div class="airport">{{ returnFlightsData[0].origin }}</div>
					</div>

					<div class="journey">
						<div class="journey-length">
							{{ formatTimeRange(returnFlightsData[0].journeyLength) }}
						</div>
						<div class="line">
							<div class="small-line"></div>
							<div class="ball start"></div>
							<div class="layover-balls">
								<div v-for="layover in returnFlightsData[0].changes" :key="layover" class="ball middle"></div>
							</div>
							<div class="ball end"></div>
						</div>
						<div class="layovers">
							<div v-if="returnFlightsData[0].isDirect">
								Direct
							</div>
							<div v-else>
								{{returnFlightsData[0].changes}} layovers
							</div>
						</div>
					</div>
				
					<div class="detail end">
						<div class="time">
							{{ formatTime(returnFlightsData[0].arrivalTime) }}
						</div>
						<div class="airport">
							{{ returnFlightsData[0].destination }}
						</div>
					</div>
				</div>
				<div class="airline-name">{{ returnFlightsData[0].carrierName }}</div>
			</div>
			<div class="total-row">
				<button class="button small" @click="viewDetails()">
					Select
				</button>
				<!-- <div class="carbon-score">
					<div class="carbon-inner">
						<img src="@/assets/co2-cloud-icon.png" alt="co2 score round cloud icon" class="co2-score"/>
						<span class="score">
							xyz kg
						</span>
					</div>
				</div> -->
				<div class="price">
					<div class="total-price" v-if="returnFlightsData.length > 0">
						{{ formatPrice(returnFlightsData[0].currency, returnFlightsData[0].price, 1) }}
					</div>
					<div class="total-price" v-else>
						{{ formatPrice(data.price.currency, data.price.from, 1) }}
					</div>
				</div>
			</div>
			<div class="border-line under-price"></div>
			<div class="dropdown-container price-breakdown-header">
				<div class="dropdown">
					<div class="price-breakdown">
						<div class="subheading" v-on:click="() => {priceBreakdown = !priceBreakdown}">
							<div>
								<img src="@/assets/flights-search/price-icon-grey.png" alt="banknote-icon" class="banknote-icon"/> Prices
							</div>
							<div class="down-arrow" v-if="priceBreakdown">
								<div class="arrow-line-left-up arrow-line-left"></div>
								<div class="arrow-line-right-up arrow-line-right"></div>
							</div>
							<div class="down-arrow" v-else>
								<div class="arrow-line-left"></div>
								<div class="arrow-line-right"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="price-breakdown">
					<div v-if="this.priceBreakdown" class="prices-inner">
						<div class="type" v-for="(type, index) in data.price.pax" :key="index">
							<div class="inner" v-if="index == 'adult'">
								<img src="@/assets/flights-search/adult-grey.png" alt="adult-icon" class="pax-icon adult"/> 
								{{ qty.adults }} {{ index }} {{ formatPrice(data.price.currency, (type.each * qty.adults), 1) }}
							</div>
							<div class="inner" v-if="index == 'child'">
								<img src="@/assets/flights-search/child-grey.png" alt="child-icon" class="pax-icon child"/> 
								{{ qty.children }} {{ index }} {{ formatPrice(data.price.currency, (type.each * qty.children), 1) }}
							</div>
							<div class="inner" v-if="index == 'infant'">
								<img src="@/assets/flights-search/infant-grey.png" alt="infant-icon" class="pax-icon infant"/> 
								{{ qty.infants }} {{ index }} {{ formatPrice(data.price.currency, (type.each * qty.infants), 1) }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="this.priceBreakdown && returnFlightsData.length != 0" class="border-line display-rtn"></div>
			<!-- all return opts -->
			<div class="dropdown-container return-opt-header" v-if="returnFlightsData.length != 0">
				<div class="dropdown">
					<div class="return-options" v-on:click="() => {showReturnOpts = !showReturnOpts}">
						<div class="return-opt-text">
							{{ returnFlightsData.length }} return options
						</div>
						<div class="down-arrow" v-if="showReturnOpts">
							<div class="arrow-line-left-up arrow-line-left"></div>
							<div class="arrow-line-right-up arrow-line-right"></div>
						</div>
						<div class="down-arrow" v-else>
							<div class="arrow-line-left"></div>
							<div class="arrow-line-right"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="this.showReturnOpts" class="options-area">
			<div class="return-opt-row-outer" v-for="(returnFlight, index) in returnFlightsData" :key="index">
				<div class="return-opt-row" @click="returnKey = returnFlight.key">
					<input type="radio" v-bind:name="$vnode.key" v-bind:id="returnFlight.key" v-bind:value="returnFlight.key" v-model="returnKey" class="return-checkbox" />
					<div class="logo-container">
						<img class="airline-logo" alt="Airline Logo" width="36px" :src="getCarrierLogoPath(returnFlight.carrierCode)" @error="setDefaultCarrierLogo" />
						<div class="carrier-code" v-text="returnFlight.carrierCode"></div>
					</div>
					<div class="rtn-opt-flight">
						<div class="timing">
							<div class="outbound">
								<div class="time">{{ formatTime(returnFlight.departureTime) }}</div>
								<span class="airport">{{returnFlight.origin}}</span>
							</div>
							<span class="dash">-</span> 
							<div class="inbound">
								<div class="time">{{ formatTime(returnFlight.arrivalTime) }}</div>
								<span class="airport">{{returnFlight.destination}}</span>
							</div>
						</div>
						<div v-if="returnFlight.changes == 0" class="info">Direct - {{ formatTimeRange(returnFlight.journeyLength) }}</div>
						<div v-else class="info">
							<span class="layovers">{{returnFlight.changes}} layovers</span>
							<span class="length">{{ formatTimeRange(returnFlight.journeyLength) }}</span>
						</div>
					</div>
					<div class="rtn-opt-price">
						<div class="price">{{ formatPrice(returnFlight.currency, returnFlight.price, 1) }}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';

    export default {
        name: 'FlightsOption',
        props: ['data', 'qty'],
		data: function () {
			return {
				showReturnOpts: false,
				priceBreakdown: false,
				returnKey: false,
			}
		},
        methods: {
			getCarrierLogoPath: (code) => helpers.getCarrierLogoPath(code),
			setDefaultCarrierLogo: (event) => helpers.setDefaultCarrierLogo(event),
			viewDetails() {
				if(this.returnKey == "" && this.returnFlightsData.length > 0) {
					this.returnKey = this.returnFlightsData[0].key; // First option as default
				}
				if(this.returnKey) {
					router.push({ 
						name: 'Flights Details', 
						params: { 
								outbound_key: encodeURIComponent(this.$vnode.key), 
								return_key: encodeURIComponent(this.returnKey)
							}
						}
					);
				} else {
					router.push({ 
						name: 'Flights Details', 
						params: { 
								outbound_key: encodeURIComponent(this.$vnode.key), 
							}
						}
					);
				}
				
			},
			formatTimeRange: (timeStamp) => helpers.formatTimeRange(timeStamp),
			formatTime: (timeStamp) => helpers.formatTime(timeStamp),
			formatPrice: (currency, to, howmany, decimals) => helpers.formatPrice(currency, to, howmany, decimals),
		},
		computed: {
			// Determine the flight start / end details (cutting out any changes of planes) and make computed variables.
			outboundFlightsData() {
				var keyedData = Object.keys(this.data.flights);
				var firstFlight = this.data.flights[keyedData[0]];
				var lastFlight = this.data.flights[keyedData[keyedData.length - 1]];

				var data = {};
					data.totalFlights = keyedData.length;
					data.isDirect = keyedData.length == 1 ? true : false;
					data.departureTime = firstFlight.segment_info.DepartureTime;
					data.arrivalTime = lastFlight.segment_info.ArrivalTime;
					data.changes = keyedData.length - 1;
					data.journeyLength = this.data.travel_time;
					data.origin = firstFlight.origin.code;
					data.destination = lastFlight.destination.code; 
				return data;
			},
			returnFlightsData() {
				var data = [];
				if(this.data.hasOwnProperty('return_options')) {
					let returnOpts = this.data.return_options;
					let returnOptKeys = Object.keys(returnOpts);
					for(let i = 0; i < returnOptKeys.length; i++) {
						let curr = returnOpts[returnOptKeys[i]];
						var keyedData = Object.keys(curr.flights);
						let returnObj = {
							key: returnOptKeys[i],
							carrierName: curr.carrier.name,
							carrierCode: curr.carrier.code,
							totalCO2: Math.round(curr.total_co2),
							price: curr.price.amount,
							currency: curr.price.currency,
							currencySymbol: curr.price.symbol,
							departureTime: curr.departure_display,
							arrivalTime: curr.arrival_display,
							journeyLength: curr.travel_time,
							origin: curr.flights[keyedData[0]].origin.code,
							destination: curr.flights[keyedData[keyedData.length -1]].destination.code,
							changes: keyedData.length - 1,
							isDirect: keyedData.length == 1 ? true : false,
						}
						data.push(returnObj);	
					}
				}
				return data;
			},
		},
    }
</script>

<style scoped>
	.card-outer {
		position: relative;
		border: 1px solid #E5E5E5;
		max-width: 346px;
		margin: 0 auto;
		margin-top: 10px;
		border-radius: 10px;
	}
	.card-inner {
		padding: 23px 30px;
	}
	.card-outer .flight-opt-row {
		margin-bottom: 15px;
	}
	.card-outer .total-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 5px;
	}
	.card-outer .total-row .button.small {
		height: 30px;
		width: 100px;
		line-height: 20px;
		margin: 0;
	}
	.card-outer .total-row .price {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: right;
		color: #000000;
	}
	.card-outer .total-row .carbon-score {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
	}
	.card-outer .total-row .carbon-score img.co2-score {
		width: 37px;
		height: 37px;
		vertical-align: middle;
	}
	.card-outer .total-row .carbon-score .score {
		font-size: 10px;
		line-height: 12px;
		font-weight: 700;
	}
	.card-outer .border-line {
		height: 1px;
		background: #E5E5E5;
		margin: auto;
	}
	.card-outer .border-line.under-price {
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.card-outer .dropdown {
		margin: 0 auto;
	}
	.card-outer .price-breakdown-header .dropdown {
		text-align: right;
	}
	.dropdown-container.return-opt-header {
		margin-top: -20px;
	}
	.card-outer .return-opt-header .dropdown {
		text-align: left;
	}
	.card-outer .border-line.display-rtn {
		margin-top: 15px;
		margin-bottom: 33px;
	}
	.card-outer .price-breakdown {
		font-size: 12px;
		margin: 0 auto;
		margin-bottom: 0px;
	}
	.card-outer .dropdown .price-breakdown {
		margin-bottom: 0px;
	}
	.card-outer .dropdown .return-options, .card-outer .dropdown .price-breakdown {
		display: inline-block;
		width: 50%;
		cursor: pointer;
		display: inline-flex;
		font-size: 12px;
		font-weight: 600;
		line-height: 15px;
		color: #50555C;
	}
	.card-outer .dropdown .return-options {
		justify-content: start;
	}
	.card-outer .dropdown .price-breakdown {
		justify-content: end;
	}
	.card-outer .dropdown .price-breakdown .subheading {
		display: flex;
	}
	.card-outer .dropdown .price-breakdown img.banknote-icon {
		width: 16px;
		height: 10px;
		margin-top: 3px;
	}
	.card-outer .dropdown .arrow-line-left, .card-outer .dropdown .arrow-line-right {
		width: 7px;
		height: 2px;
	}
	.card-outer .dropdown .arrow-line-right {
		margin-left: 5px;
		margin-top: -2px;
	}
	.card-outer .price-breakdown .prices-inner {
		color: #000000;
		display: inline-flex;
		width: 100%;
		justify-content: right;
		column-gap: 10px;
		margin-top: 15px;
	}
	.card-outer .price-breakdown .type {
		text-transform: capitalize;
		font-size: 11px;
	}
	.card-outer .price-breakdown .type .inner {
		display: flex;
		align-items: center;
	}
	.card-outer .price-breakdown .type img.pax-icon {
		width: 21px;
		height: 21px;
		vertical-align: middle;
		margin-right: 3px;
	}

	.card-outer .arrow-line-left {
		width: 12px;
		height: 3px;
		transform: rotate(43deg);
		background: #50555C;
	}
	.card-outer .arrow-line-left-up {
		width: 12px;
		height: 3px;
		transform: rotate(43deg);
		background: #50555C;
	}
	.card-outer .arrow-line-right {
		width: 12px;
		height: 3px;  
		transform: rotate(-43deg);
		background: #50555C;
		margin-left: 7px;
		margin-top: -3px;
	}
	.card-outer .arrow-line-right-up {
		transform: rotate(-133deg);
	}
	.card-outer .arrow-line-left-up {
		transform: rotate(134deg);
		padding-right: 1px;
	}
	.card-outer .down-arrow {
		margin-left: 5px;
		margin-top: 8px;
	}
	.card-outer .options-area {
		margin-top: -15px;
		margin-bottom: 10px;
	}

	.card-outer .return-opt-row {
		display: flex;
		cursor: pointer;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-top: 10px;
		margin-bottom: 6px;
	}
	.card-outer .rtn-opt-price {
		margin-right: 25px;
	}
	.card-outer .rtn-opt-flight {
		flex-grow: 2;
	}
	.card-outer .return-opt-row-outer {
		border-bottom: 1px solid #E5E5E5;
		background-color: #FBFBFB;
	}
	.card-outer .return-opt-row-outer:last-of-type {
		border-bottom: none;
	}
	
	.card-outer .return-opt-row .return-checkbox {
		margin-left: 25px;
		z-index: 10;
		width: 15px;
		height: 15px;
		background-color: white;
		border-radius: 50%;
		vertical-align: middle;
		border: 2px solid #118AB2;
		-webkit-appearance: none;
		outline: none;
		margin-top: 5px;
		cursor: pointer;;
	} 
	.card-outer .return-opt-row .return-checkbox:checked {
		border: 2px solid #118AB2;
		background: #118AB2;
	}
	.card-outer .return-opt-row .airline-logo {
		width: 36px;
		object-fit: contain;
    	margin: 0 10px;
	}
	.card-outer .return-opt-row .timing {
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		width: initial;
		display: flex;
	}
	.card-outer .return-opt-row .time {
		color: #000000;
	}
	.card-outer .return-opt-row .timing .airport {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #50555C;
	}
	.card-outer .return-opt-row .price {
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		text-align: right;
		color: #000000;
	}
	.card-outer .return-opt-row .purpose-score {
		color: #60D2A3;
		font-weight: 600;
		font-size: 8px;
		line-height: 16px;
		margin-top: -8px;
	}
	.card-outer .return-opt-row .circle {
		border: 2px solid #60D2A3;
		border-radius: 50%;
		width: 25px;
		height: 25px;
		font-size: 10px;
		text-align: center;
		line-height: 25px;
	}
	.card-outer .return-opt-row .dash {
		padding-left: 8px;
		padding-right: 8px;
	}
	.card-outer .return-opt-row .info {
		font-weight: 600;
		font-size: 11px;
		line-height: 24px;
	}
	.card-outer .return-opt-row .info .layovers {
		color: #118AB2;
		margin-right: 5px;
	}
	.card-outer .return-opt-row .info .length {
		color: #161D27;
	}

</style>
